import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "score": 1,
  "featured": false,
  "tileImage": "./tile-image-01.png",
  "tileTextColorScheme": "light",
  "types": ["system", "event"],
  "status": ["completed"],
  "title": "Saving Water at Roskilde Festival.",
  "subtitle": "Aqubiq X Roskilde Festival powered by DTU Students",
  "description": "",
  "years": ["2017"],
  "institutions": ["dtu", "aqubiq"],
  "location": "cph",
  "keywords": {
    "topics": ["iot", "sustainability"],
    "tools": ["particle photon", "wiring", "c/c++"]
  },
  "people": [{
    "name": "Niklas Buhl",
    "role": "Designer & Developer"
  }, {
    "name": "Josefine Lange Strandgaard",
    "role": "Designer"
  }, {
    "name": "Peter Nørtoft",
    "role": "Supervisor, Aqubiq CEO"
  }, {
    "name": "Andreas Vejbæk Mønster",
    "role": "Aqubiq CTO"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Danish Title: Spar Vand Smartere`}</h2>
    {
      /* # Saving Water at Roskilde Festival */
    }
    <p>{`Engaging people through events and gamification to reduce water consumption at Roskilde Festival, and monitoring with internet connected sensors.
Aqubiq.
In partnership with Aqubiq we completed a project at Roskilde Festival to monitor water consumption and see in which ways we could encourage festival goers and food court workers to reduce their water usage.
We designed at water saving campaign with several point of entry in addition to upgrading their sensor system and deploy it into the water infrastructure at the festival.
The campaign were a daily competitions, posters, temporary tattoos. In order to make people have attention to their usage.
The upgrade to Aqubiqs sensor system were a module to save important data on a flashdrive instead of relying on internet connection. As it is my experience from previous projects and personal experience at Roskilde Festival - There is no certainty of internet, power, no-being peed on or stolen.
Implementing IoT system to measure water consumption at Roskilde Festival to analyse for reduction. In collaboration with the company Aqubiq we upgraded their sensor system and deployed it into the water infrastructure at the festival to reduce water consumption.
Attention to water consumption at Roskilde Festival
We designed a water-saving-campaign in addition to upgrading their sensor sys- tem and deployed it into the water infrastructure at RF to reduce water consumption. Making participants get attention to their usage.
Implementing IoT system to measure water consumption at Roskilde Festival to analyse for reduction. In collaboration with the company Aqubiq we upgraded their sensor system and deployed it into the water infrastructure at the festival to reduce water consumption.`}</p>
    <p>{`QR Kode
Experiment
Opsyn med vandforbruget
“Man sparer 15% bare ved at være opmærksom på sit forbrug…"
Kontakt til bæredygtige boder
Pas på at det ikke bliver top-down
Display på boden
Videre med projektet?
Ansøg økonomiske midler fra…?`}</p>
    <h2>{`Vandmærke`}</h2>
    <h2>{`Activities`}</h2>
    <ul>
      <li parentName="ul">{`Project at Roskilde Festival.`}</li>
      <li parentName="ul">{`Development of extension to save information on SD.`}</li>
      <li parentName="ul">{`General projecting with Roskilde Festival and design of the campaign.`}</li>
      <li parentName="ul">{`Implementing the SD driver into the firmware of the Aqubiq sensor. Based on Particle with C/C++ programming and some simple wiring.`}</li>
      <li parentName="ul">{`On-site trouble shooting and maintenance of the systems.`}</li>
    </ul>
    <h2>{`SD Card Reader`}</h2>
    <ul>
      <li parentName="ul">{`Hardware`}
        <ul parentName="li">
          <li parentName="ul">{`Adafruit SD Card Module`}</li>
          <li parentName="ul">{`Particle Photon`}</li>
          <li parentName="ul">{`Photon and SD Projects`}
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://community.particle.io/t/photon-and-sd-card-library-error/14707/2"
                }}>{`https://community.particle.io/t/photon-and-sd-card-library-error/14707/2`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://community.particle.io/t/particle-photon-oled-screen-and-sd-card-demo/18145"
                }}>{`https://community.particle.io/t/particle-photon-oled-screen-and-sd-card-demo/18145`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://www.codemade.io/particle-photon-oled-screen-and-sd-card-demo-tutorials-particle/"
                }}>{`https://www.codemade.io/particle-photon-oled-screen-and-sd-card-demo-tutorials-particle/`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`Code References`}
        <ul parentName="li">
          <li parentName="ul">{`Particle Wiring C Reference`}</li>
          <li parentName="ul">{`FatFs Reference`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Code Repositories`}
        <ul parentName="li">
          <li parentName="ul">{`FatFs SD Library`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Other SD Libraries`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/greiman/SdFat-Particle"
            }}>{`https://github.com/greiman/SdFat-Particle`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`Other Code`}
        <ul parentName="li">
          <li parentName="ul">{`// SD.highSpeedClock(25000000);`}</li>
          <li parentName="ul">{`// pinMode(SD_CD, INPUT_PULLUP);`}</li>
          <li parentName="ul">{`// SD.enableCardDetect(SD_CD, HIGH);`}</li>
        </ul>
      </li>
    </ul>
    <h2>{`Mentionable Results`}</h2>
    <p>{`People use considerable less water than in their average daily life.`}</p>
    <h2>{`Links / References`}</h2>
    <p><a parentName="p" {...{
        "href": "https://roskilde.dtu.dk/projekter/projekter-fra-tidligere-aar/spar-vand-smartere"
      }}>{`https://roskilde.dtu.dk/projekter/projekter-fra-tidligere-aar/spar-vand-smartere`}</a>{`
LinkedIn - Roskilde Powered by DTU Student #RF17 - Daniel Blitcher
`}<a parentName="p" {...{
        "href": "https://aqubiq.com/"
      }}>{`https://aqubiq.com/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      